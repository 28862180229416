import { Plan, Track } from '../@types'

const production = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'

export const behaviorPlans: Plan[] = [
  {
    id: '3-MO-C',
    sku: '3-MO-C',
    duration: 3,
    amount: 14700,
    name: 'Measured Health',
    line_item: 'Measured Health',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_LxIZ22jtI85hsu'
      : 'prod_K14tgJvWDpUJcK',
    stripe_price_id: production
      ? 'price_1LFNylBS2APHTgAqTEQk9yXO'
      : 'price_1KOXcpBS2APHTgAqsCVvRDqB',
    deal: null,
    billed_long: '$147 every 3 months',
    originalPrice: 49,
    salePrice: 0,
    weeklyPrice: 12.5,
    monthlyPrice: 49,
    quarterlyPrice: 147,
    productName: 'Monthly Membership',
    productNameLong: 'Measured Health',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    offers: null,
    track: Track.BEHAVIORAL,
    freeTrial: true,
    trialDays: 30,
    discount: {
      name: 'First Month Free',
      caption: 'Limited Time Promo',
      amount: 49,
      showPercentage: false,
    },
    addons: [{
      id: 'DIETITIAN',
      sku: 'DIETITIAN',
      amount: 2900,
      originalPrice: 29,
      salePrice: 29,
      name: 'Initial Dietitian Consultation',
      shortName: 'Dietitian Consultation',
      caption: 'One-time fee',
      stripe_product_id: production ? 'prod_LxLIb26iaxIYkE' : 'prod_LxLIpLgz6dXEIU',
      stripe_price_id: production ? 'price_1LFQbyBS2APHTgAqYADOV6kd' : 'price_1LFQbpBS2APHTgAqxIedw7U7',
      recurring: false,
    }]
  },
]
export const medicalPlans: Plan[] = [
  {
    id: '3-MO-RX',
    sku: '3-MO-RX',
    duration: 3,
    amount: 29700,
    name: 'Measured Rx',
    line_item: 'Measured Rx',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_LxIZUveQFJcZHS'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1LFNyABS2APHTgAqOYHlZmvw'
      : 'price_1LFO8HBS2APHTgAqUAGEGxPE',
    deal: null,
    billed_long: '$297 every 3 months',
    originalPrice: 99,
    salePrice: 0,
    weeklyPrice: 25,
    monthlyPrice: 99,
    quarterlyPrice: 297,
    productName: 'Monthly Membership',
    productNameLong: 'Measured Rx',
    // caption: 'Billed Quarterly',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    offers: null,
    track: Track.MEDICAL,
    freeTrial: true,
    trialDays: 30,
    discount: {
      name: 'First Month Free',
      caption: 'Limited Time Promo',
      amount: 99,
      showPercentage: false,
    },
    addons: [{
      id: 'DR-VISIT',
      sku: 'DR-VISIT',
      amount: 4900,
      originalPrice: 49,
      salePrice: 49,
      name: 'Initial Doctor Consultation',
      shortName: 'Initial Dr Visit',
      caption: 'One-time fee',
      stripe_product_id: production ? 'prod_LxIX9aHPVwjDb5' : 'prod_LxIa58zczvu3qM',
      stripe_price_id: production ? 'price_1LFNw1BS2APHTgAqEsHNhndC' : 'price_1LFNzhBS2APHTgAqMK0XSwRy',
      recurring: false,
    }]
  },
]


const plans = { medicalPlans, behaviorPlans }

export const defaultPlan = (track: Track) =>
  track === 'medical' ? plans.medicalPlans[0] : plans.behaviorPlans[0]

export default plans
