import { useRouter } from "next/router";
import React from "react";

export const trackMixpanelEvent = (event_name: string, props?: { url: any; }) => {
  try {
    if ((window as any).mixpanel) {
      (window as any).mixpanel.track(event_name, props);
    }
  } catch (e) {
    console.log(e);
  }
}

export const MixpanelTracker = function () {
  const router = useRouter();

  const handleRouteChange = (url: any) => {
    //Send track event when new pages is loaded
    console.log('Tracking', url);
    trackMixpanelEvent('Page view', {
      url
    })
  }

  React.useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.asPath, router.events])
  return null;
};
