import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux/hooks";
import { clearQuizProgress } from "redux/quiz/steps";
import Header from "../components/Header";
import Footer from "../components/Footer";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const router = useRouter();
  const { asPath } = router;
  const pathArray = asPath.split('/');
  const pathClass = pathArray.map(p => {
    if (!isNaN(parseFloat(p))) {
      return `step-${p}`;
    } else {
      return p.replace(/\//g, "-");
    }
  }).join(' ');

  useEffect(() => {
    router.events.on("routeChangeStart", () => {
      dispatch(clearQuizProgress());
    });
  }, [dispatch, router.events])

  return (
    <div className={`layout h-screen flex flex-col px-4 md:px-0 mx-auto ${pathClass}`}>
      <Header />
      <div className="items-center flex flex-col flex-1">
        <div className="layout-inner-1">
          <div className="layout-inner-2">
            <div className="w-full flex flex-col items-center justify-start md:pt-8">
              {children}
            </div>
          </div>
        </div>
        <Footer path={asPath} />
      </div>
    </div >
  );
}

export default Layout;
